import React from "react";
import logo from "../../img/scrumanac.png";
import styled from "styled-components";
import { Link } from "gatsby";
const LogoContainer = () => {
  return (
    <Container to="http://www.scrumanac.com/">
      <StyledImg image={logo}></StyledImg>
      <CompanyName> Scrumanac</CompanyName>
    </Container>
  );
};

const StyledImg = styled.img.attrs((props) => ({
  src: props.image,
}))`
  margin-top: 10px;
  height: 35px;
  width: 35px;
  @media (max-width: 1025px) {
    margin-top: 18px;
    width: 25px;
    height: 25px;
  }
`;

const CompanyName = styled.p`
  padding-top: 2.5px;
  padding-left: 1rem;
`;

const Container = styled(Link)`
  display: flex;
  margin-left: 10px;
  margin-right: 90px;
  padding-top: 9px;
  & p {
    color: #fff;
    font-weight: 700;
    font-size: 25px;
    margin-top: 12px;
  }
  @media (max-width: 1025px) {
    order: 0;
    margin-right: 10px;
  }

  @media (max-width: 422px) {
    width: 30%;
  }
`;

export default LogoContainer;
