import React, { useState } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { FaLongArrowAltDown } from "react-icons/fa";

const NavbarItem = ({ name, dropdownArrow, dropDownData, showSearch }) => {
  const [hover, setHover] = useState(false);
  const Toggle = () => {
    setHover(!hover);
  };
  return (
    <NavItemContainer
      onMouseEnter={Toggle}
      onMouseLeave={Toggle}
      showSearch={showSearch}
      name={name}
      // href={`/${name.toLowerCase()}/`}
      href={`#`}
    >
      <NavSubMenu name={name}>
        {name}
        {dropdownArrow ? <StyledArowDown size={15} /> : ""}
      </NavSubMenu>
      <DropDownContent hover={hover}>
        {dropDownData
          ? dropDownData.map((item) => (
              <Link href={item?.href}>
                <DropdownItem>{item.item}</DropdownItem>
              </Link>
            ))
          : ""}
      </DropDownContent>
    </NavItemContainer>
  );
};

const DropDownContent = styled.div`
  display: none;
  margin-top: 0px;
  position: absolute;
  flex-direction: column;
  text-transform: none;
  background: #0066b2;
  color: #fff;
  min-width: 290px;
  box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
  z-index: 1;
  @media (max-width: 1110px) {
    display: ${(props) =>
      props.hover ? "none !important" : "none !important"};
  }
`;

const NavItemContainer = styled(Link)`
  flex-basis: 100%;
  position: relative;
  display: inline-block;
  height: 100%;
  font-weight: 700;
  font-size: 0.75rem;
  text-transform: uppercase;
  transition: background 0.3s;
  font-weight: 800;
  & :hover {
    background: #0066b2;
    color: #fff;
  }
  &::after {
    content: "";
    width: ${(props) => (props.name === "Blog" ? "0px" : "1px")};
    height: ${(props) => (props.name === "Blog" ? "0px" : "30px")};
    position: absolute;
    top: 50%;
    right: 0;
    background: ${(props) => (props.name === "Blog" ? "none" : "#272727")};
  }
  @media (max-width: 1000px) {
    margin-top: 0px;
    height: 100%;
    width: 100%;

    background: #191919;
  }
  &:hover ${DropDownContent} {
    display: block;
  }
`;
const NavSubMenu = styled.li`
  color: #bbb;
  height: 100%;
  width: 100%;
  font-weight: 800;
  padding-left: 20px;
  padding-right: 20px;
  justify-content: center;
  padding-top: 33px;
  padding-bottom: 20px;
  text-align: center;
  display: flex;
  align-self: center;
  &::before {
    content: "";
    width: ${(props) => (props.name === "Blog" ? "0px" : "1px")};
    height: ${(props) => (props.name === "Blog" ? "0px" : "30px")};
    position: absolute;
    top: 50%;
    right: 0;
    background: #272727;
    margin-top: -15px;
  }

  &:hover {
    color: #fff;
  }
  @media (max-width: 1000px) {
    margin-top: 0px;
    z-index: 100;
    height: 100%;
    width: 100%;
    padding-bottom: 15px;
    padding-top: 25px;
    font-weight: 800;
    justify-content: center;
    background: #191919;
  }
`;

const StyledArowDown = styled(FaLongArrowAltDown)`
  padding-top: 2px;
  margin-left: 0px;
  color: grey;
  &:hover {
    color: #fff;
  }
`;

const DropdownItem = styled.p`
  background: #0066b2;
  color: #fff;
  text-align: left;
  font-size: 0.875rem;
  font-family: 'Archivo';
  font-weight: 400;
  padding: 0.5rem 1.5rem;
  &:hover {
    color: #fff !important;
    font-weight: 500;
    filter: brightness(120%);
  }
  @media (max-width: 970px) {
    display: none;
  }
`;

export default NavbarItem;
