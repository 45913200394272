import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";

const ContactBtn = ({ showSearch, showNav }) => {
  return (
    <Container to="#contactus">
      <Link to="/#contactus">
        <Button showSearch={showSearch} showNav={showNav}>
          Contact us
        </Button>
      </Link>
    </Container>
  );
};

const Container = styled(Link)`
  display: block;
  background: #191919;
  order: 9;
  @media (max-width: 1024px) {
    order: 9;
    width: 100%;
    padding-right: 40px;
    @media (max-width: 1164px) {
      padding-right: 10px;
    }
  }
`;

const Button = styled.button`
  color: #fff;
  font-weight: 800;
  background: rgba(0, 152, 212, 0.15);
  font-size: 0.75rem;
  font-family: 'Archivo';
  width: 155px;
  display: block;
  height: 55px;
  order: 7;
  padding: 0 1.9rem;
  text-transform: uppercase;
  margin-top: 12px;
  margin-right: 10px;
  margin-bottom: 3px;
  border: 1px solid #0066b2;
  opacity: 1;
  &:hover {
    opacity: 1;
    background: #0066b2;
  }

  @media (max-width: 1025px) {
    order: 6;
    display: ${(props) => (props.showNav ? "block" : "none")};
    width: 100%;
    margin-top: 20px;
    margin-left: 10px;
    margin-bottom: 20px;
  }
`;

export default ContactBtn;
