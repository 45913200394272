import React, { useState, useRef } from "react";
import styled from "styled-components";
import Button from "./SubmitBtn";
import Input from "./InputField";
import Select from "./SelectInputField";
import TextArea from "./TextArea";
import { GrFormClose } from "react-icons/gr";

const CustomersForm = ({ displayCustomers }) => {
  const [inputValue, setInputValue] = useState({
    name: "",
    email: "",
    message: "",
    service: "",
    type: "customers",
  });
  const [submitContact, setSubmitContact] = useState(false);
  const [showAlert, setShowAlert] = useState(false);
  const [formError, setFormError] = useState(false);
  const AlertRef = useRef(null);
  const FormRef = useRef(null);

  const handleChange = (e) => {
    e.preventDefault();
    setInputValue({ ...inputValue, [e.target.name]: e.target.value });
  };

  const CloseAlert = (e) => {
    AlertRef.current.style.opacity = "0";
  };

  return (
    <Container displayCustomers={displayCustomers}>
      <FormTop>
        <FormTitle>APPLICATION DEVELOPMENT SERVICES</FormTitle>
        <ForSubTitle>
          Please select your type of project request and submit the form, or
          contact us at hello@scrumanac.com for more information on Scrumanac's
          services and technologies.
        </ForSubTitle>
        <AlerBox ShowAlert={showAlert} ref={AlertRef}>
          {formError ? (
            <h4>Form fields cannot be empty</h4>
          ) : (
            <h4>
              Message sent ,you will get a response in your mail shortly thank
              you
            </h4>
          )}

          <StyledCloseIcon size={27} onClick={CloseAlert} />
        </AlerBox>
      </FormTop>
      <FormContainer>
        <Form ref={FormRef}>
          <Select name={"service"} changeEvent={handleChange} />
          <Input
            changeEvent={handleChange}
            value={inputValue.name}
            label={"name"}
            placeholder="Name"
            type="text"
          ></Input>
          <Input
            changeEvent={handleChange}
            value={inputValue.email}
            label={"email"}
            type="text"
            placeholder="Email Address"
          ></Input>
          <TextArea
            changeEvent={handleChange}
            label={"message"}
            value={inputValue.message}
            placeholder="Please provide more details"
          ></TextArea>
          <Button
            type="submit"
            title="Submit"
            formValue={inputValue}
            formRef={FormRef}
            submitStatus={submitContact}
            setSubmitStatus={setSubmitContact}
            setAlertStatus={setShowAlert}
            errorStatus={setFormError}
          />
        </Form>
      </FormContainer>
    </Container>
  );
};

const Form = styled.form`
  width: 100%;
`;

const Container = styled.div`
  display: ${(props) => (props.displayCustomers ? "block" : "none")};
  transition: display ease-in 10s;
`;
const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-left: auto;
  margin-right: auto;
  color: #eae9e0;
  @media (max-width: 570px) {
    width: 350px;
  }
  @media (max-width: 450px) {
    width: 250px;
  }
`;

const FormTop = styled.div`
  max-width: 930px;
  margin: 4rem auto;
  font-family: 'Archivo';
  text-align: center;
`;

const FormTitle = styled.p`
  font-size: 1.25rem;
  line-height: 1;
  margin-bottom: 1rem;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
`;
const ForSubTitle = styled.p`
  font-size: 1rem;
  line-height: 1.25;
  color: #fff;
  width: 100%;
  font-family: 'Archivo';
  margin-right: auto;
  margin-left: auto;
  margin-top: 0;
  margin-bottom: 1rem;
`;

const AlerBox = styled.div`
  margin-top: 0px;
  background-color: #9dc183;
  color: white;
  font-size: 15px;
  height: 100%;
  display: flex;
  border-radius: 6px;
  justify-content: space-between;
  padding-left: 3px;
  padding-top: 8px;
  opacity: ${(props) => (props.ShowAlert ? 1 : 0)};
  /* opacity: 1; */
  transition: opacity 1s;
  @media (max-width: 970px) {
    margin-top: 40px;
    height: 74px;
    width: 100%;
  }
`;

const StyledCloseIcon = styled(GrFormClose)`
  color: #0066b2;
  border-radius: 6px;
  cursor: pointer;
`;

export default CustomersForm;
