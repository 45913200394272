import React from "react";
import styled from "styled-components";
import { FaChevronRight } from "react-icons/fa";
import Axios from "axios";
import Loader from "../../img/loader.gif";

const SubmitBtn = ({
  title,
  formValue,
  submitStatus,
  setSubmitStatus,
  setAlertStatus,
  formRef,
  errorStatus,
}) => {
  const Url =
    "https://m2ep0yt5j1.execute-api.ap-northeast-1.amazonaws.com/dev/contact";

  const FormValidator = (obj) => {
    try {
      for (var key in obj) {
        if (obj[key] === null || obj[key] === "") {
          throw new Error({ error: `Invalid input in ${key}` });
        }
        return true;
      }
    } catch (error) {
      return error;
    }
  };

  const ButtonClick = (e) => {
    e.preventDefault();
    const validateForm = FormValidator(formValue);

    if (!validateForm.error) {
      setSubmitStatus(true);
      Axios.post(Url, formValue)
        .then((data) => {
          formRef.current.reset();
          setSubmitStatus(false);
          errorStatus(false);
          setAlertStatus(true);
        })
        .catch((error) => {
          setSubmitStatus(false);
          formRef.current.reset();
          errorStatus(false);
          setAlertStatus(true);
        });
    } else {
      errorStatus(true);
      setAlertStatus(true);
    }
  };

  return (
    <Button onClick={ButtonClick}>
      {!submitStatus ? (
        <>
          <p> {title}</p>
          <p>
            {" "}
            <StyledChevronRight size={20} />{" "}
          </p>{" "}
        </>
      ) : (
        <StyledLoader src={Loader} />
      )}
    </Button>
  );
};

const Button = styled.button`
  justify-content: center;
  padding-top: 20px;
  display: flex;
  width: 100%;
  font-size: 18px;
  height: 4rem;
  margin-bottom: 2rem;
  margin-top: 15px;
  box-shadow: none;
  border: 3px solid #0066b2;
  opacity: 0.75;
  border-radius: 6px;
  color: #fff;
  font-family: 'Archivo';
  font-weight: 600;
  text-transform: uppercase;
  letter-spacing: 1.6px;
  will-change: background, opacity;
  transition: background 0.3s, opacity 0.3s;
  transition: background 2s;
  background: #0066b2;

  &:hover {
    opacity: 1;
  }
`;

const StyledChevronRight = styled(FaChevronRight)`
  margin-top: 0.5px;
  margin-left: 10px;
  font-weight: 700;
`;

const StyledLoader = styled.img`
  width: 30px;
  height: 30px;
`;

export default SubmitBtn;
