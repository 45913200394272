import React from "react";
import { FaSearch } from "react-icons/fa";
import styled from "styled-components";
import { FaTimes } from "react-icons/fa";

const SearchIcon = ({ setShowSearch, showSearch }) => {
  const tog = () => {
    setShowSearch(!showSearch);
  };

  return (
    <SearchContainer showSearch={showSearch}>
      {showSearch ? (
        <Times showSearch={showSearch} size={22} onClick={tog}></Times>
      ) : (
        <Search showSearch={showSearch} size={30} onClick={tog}></Search>
      )}
    </SearchContainer>
  );
};

const SearchContainer = styled.div`
  order: ${(props) => (props.showSearch ? 3 : 0)};
  padding-top: 9px;
  padding-right: 20px;

  @media (max-width: 422px) {
    width: 20%;
  }

  @media (max-width: 1164px) {
    margin-left: 10px;
  }
  @media (max-width: 1025px) {
    margin-left: 45px;
    padding-right: 5px;
    order: 2;
  }
  @media (max-width: 970px) {
    margin-left: 65px;
    order: 2;
  }
  @media (max-width: 330px) {
    margin-left: 65px;
    order: 2;
  }
`;

const Search = styled(FaSearch)`
  color: #0066b2;
  margin-top: 20px;
  margin-bottom: 10px;
  margin-left: 27px;
  width: 20px;
  border: none;
  cursor: pointer;
  margin-right: 20px;
  @media (max-width: 1024px) {
    order: 2;
    width: 20px;
    margin-left: 22px;
    display: block;
    color: #0066b2;
  }
`;

const Times = styled(FaTimes)`
      color: #0066b2;
    margin-top: 23px;
    margin-bottom: 10px;
    margin-left: 35px;
    width: 20px;
    border: none;
    cursor: pointer;
    margin-right: 25px;
  @media (max-width: 1025px) {
    padding-top: 1px;
    margin-right: 20px;
    width: 20px;
    display: block;
    color: #0066b2;
  }
`;

export default SearchIcon;
