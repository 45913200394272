import React from 'react'
import styled from 'styled-components';

const TextArea = ({placeholder,changeEvent,label}) => { 

    return (
        <TextInputArea name={label} placeholder={placeholder} onChange={changeEvent} required >
        </TextInputArea>
    )
}

const TextInputArea = styled.textarea`
border-radius:10px;
width:100%;
height:80px;
padding:13px;
margin-top:10px;
font-size:17px;
font-weight: 600;
  transform:capitalize;
margin-bottom:10px;
background:#fff;
color:grey;

`;

export default TextArea
