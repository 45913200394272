import React, { useState } from "react";
import NavLogo from "./LogoContainer";
import styled from "styled-components";
import NavItem from "./NavbarItem";
import Button from "./ContactBtn";
import SearchIcon from "./SearchIcon";
import SearchInput from "./Input";
import Hamburger from "./Hamburger";

const Navbar = () => {
  const [showSearch, setShowSearch] = useState(false);
  const [showNav, setShowNav] = useState(false);

  const servicesdropDownData = [
    { item: "Dedicated Team Services", href: "/services/dedicatedteam" },
    { item: ".Net software Development" },
    { item: "Web Application Development" },
    { item: "Mobile Application Development" },
    { item: "Software architecture Design" },
    { item: "UI/UX Design Services" },
    { item: "Quality Assurance Services" },
    { item: "Application Support and Maintenance" },
    { item: "Embedded Software Development" },
  ];
  const industriesDropDownData = [
    { item: "Healthcare" },
    { item: "Real Estate" },
    { item: "Banking & Finance" },
    { item: "Streaming, Media & Telecom" },
    { item: "Logistics" },
  ];

  const aboutDropDownData = [
    { item: "About Scrumanac" },
    { item: "How we work" },
    { item: "Our Client" },
  ];

  const careerDropDownData = [
    { item: "Welcome to Scrumanac" },
    { item: "Open Vacancies" },
    { item: "Internship Program" },
  ];

  return (
    <NavContainer>
      <Container>
        <Hamburger showNav={showNav} setShowNav={setShowNav} />
        <NavLogo></NavLogo>
        <MiddleContainer showSearch={showSearch} showNav={showNav}>
          <NavItem
            href="/services"
            name={"Services"}
            dropdownArrow
            dropDownData={servicesdropDownData}
          />
          <NavItem
            name={"Industries"}
            dropdownArrow
            dropDownData={industriesDropDownData}
          />
          <NavItem name={"Technologies"} />
          <NavItem name={"Projects"} />
          <NavItem
            name={"About"}
            dropdownArrow
            dropDownData={aboutDropDownData}
          />
          <NavItem
            name={"Career"}
            dropdownArrow
            dropDownData={careerDropDownData}
          />
          <NavItem name={"Blog"} />
        </MiddleContainer>
        <SearchInput displayState={showSearch} setShowSearch={setShowSearch} />
        <SearchIcon
          setShowSearch={setShowSearch}
          showSearch={showSearch}
        ></SearchIcon>
        <Button showNav={showNav} showSearch={showSearch}></Button>
      </Container>
    </NavContainer>
  );
};

const Container = styled.div`
  font-family: 'Archivo';
  display: flex;
  width: 100%;
  background: #191919;
  font-weight: 700;
  height: 80px;
  color: #bbb;
  padding-left: 120px;
  padding-right: 130px;
  position: fixed;
  z-index: 1000;
  justify-content: space-between;
  @media (max-width: 1350px) {
    padding-left: 40px;
    padding-right: 70px;
  }
  @media (max-width: 1280px) {
    padding-left: 0px;
    padding-right: 30px;
  }
  @media (max-width: 1164px) {
    padding-left: 0px;
    padding-right: 30px;
  }

  @media (max-width: 1025px) {
    flex-wrap: wrap;
    padding-left: 0px;
    padding-right: 0px;
  }
`;

const NavContainer = styled.div`
  width: 100%;
  display: flex;
`;

const MiddleContainer = styled.div`
  display: ${(props) => (!props.showSearch ? "flex" : "none")};
  width: 70%;
  background: #191919;
  padding-left: 60px;
  padding-right: 110px;
  @media (max-width: 1399px) {
    padding-left: 0px;
    padding-right: 0px;
  }

  @media (max-width: 1025px) {
    flex-direction: column;
    order: 5;
    display: ${(props) => (props.showNav ? "flex" : "none")};
    width: 100%;
    z-index: 1000;
    justify-content: center;
  }
`;

export default Navbar;
