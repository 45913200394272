import React from "react";
import styled from "styled-components";
import { FaSearch } from "react-icons/fa";

const Input = ({ displayState, setShowSearch }) => {
  const checkBlur = () => {
    setShowSearch(!displayState);
  };

  return (
    <Container displayState={displayState}>
      <StyledInput
        displayState={displayState}
        placeholder="Search. . ."
        onBlur={checkBlur}
      />
      <Search size={30}></Search>
    </Container>
  );
};

  /* <Times showSearch={showSearch} size={30}></Times> */

const Container = styled.div`
  order: 3;
  width: 100%;
  height: 100%;
  background: #191919;
  display: ${(props) => (props.displayState ? "flex" : "none")};

  @media (max-width: 970px) {
    border: 1px solid #191919;

    height: 100px;
  }
`;

const Search = styled(FaSearch)`
    color: #0066b2;
    margin-top: 30px;
    margin-bottom: 10px;
    margin-left: 27px;
    width: 20px;
    display: none;
    border: none;
    cursor: pointer;
    margin-right: 20px;
  @media (max-width: 970px) {
    margin-top: 4px;
    display: flex;
    margin-right: 23px;
    height: 100px;
    width: 20px;
    order: 5;
    border: 1px solid #191919;
    display: block;
    color: #0066b2;
  }
`;

const StyledInput = styled.input`
  width: 80%;
  margin-top: 19px;
  height: 35px;

  padding: 15px;
  font-weight: 300;
  font-size: 19px;
  color: #fff;
  margin-left: 18px;
  border-radius: 6px;
  transition: display ease-in 1s;
  border: 0px;
  display: ${(props) => (props.displayState ? "block" : "none")};
  background: #191919;
  &:focus {
    outline: none;
  }
  @media (max-width: 970px) {
    width: 100%;
    height: 100px;
    order: 3;
    margin-top: 0px;
    margin-left: 0px;
  }
`;

export default Input;
