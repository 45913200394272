import React from 'react'
import styled from 'styled-components';

const InputField = ({type,placeholder,label,changeEvent}) => {
    return (
        <div>
               <Input  name={label} id={label} placeholder={placeholder} type={type} onChange={changeEvent} required>
            </Input>

        </div>
     

    )
}

const Input = styled.input`
border-radius:10px;
width:100%;
height:${props=>props.type ==='file'?'50px':'40px'};
padding:13px;
font-size:17px;
font-weight: 600;
margin-top:10px;
margin-bottom:10px;
background:#fff;
color: grey;
`;

export default InputField
