import React from "react";
import styled from "styled-components";
import { FaBars,FaTimes } from "react-icons/fa";


const Hamburger = ({ showNav, setShowNav }) => {
  const toggleNav = () => {
    setShowNav(!showNav);
  };

  return (
    <Container>
      {

showNav?(<Times  size={30} onClick={toggleNav}></Times>): <StyledHamburger onClick={toggleNav} size={28} />
      }

     
    </Container>
  );
};

const Container = styled.div`
  padding-top: 10px;
  @media (max-width: 422px) {
    width: 20%;
  }
`;

const StyledHamburger = styled(FaBars)`
  display: none;
  margin-top: 17px;
  color: #0066b2 !important;

  @media (max-width: 1025px) {
    display: block;
    color: #fff;
    width: 68px;
    order: 0;
  }
`;

const Times = styled(FaTimes)`
      color: #191919;
    margin-bottom: 10px;
    margin-top: 17px;
    background: transparent;
    margin-left: 27px;
    width: 20px;
    border: none;
    cursor: pointer;
    margin-right: 190px;
  @media (max-width: 1025px) {
    padding-top: 1px;
    margin-right: 18px;
    width: 20px;
    display: block;
    color: #0066b2;
  }
`;


export default Hamburger;
