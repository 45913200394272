import React, { useState } from "react";
import styled from "styled-components";
import ContactForm from "./ContactForm";
import { Link } from "gatsby";

import {
  FaFacebook,
  FaTwitter,
  FaInstagram,
  FaLinkedin,
  FaChevronUp,
  FaAt,
  FaChevronDown,
} from "react-icons/fa";

export default function Footer() {
  const [footerVisible, setFooterVisible] = useState(false);

  return (
    <Container>
      <ContactForm />
      <FooterWrapper>
        <ToggleContainer onClick={() => setFooterVisible(!footerVisible)}>
          {footerVisible ? (
            <>
              <StyledChevron size={25} /> Click to collapse
            </>
          ) : (
            <>
              <StyledChevronUp size={25} /> Click to expand
            </>
          )}
        </ToggleContainer>
        <MainFooterContainer>
          <ItemContainer>
            <TitleContainer>
              <ScrumanacTitle href="/" footerVisible={footerVisible}>
                Scrumanac
              </ScrumanacTitle>
            </TitleContainer>
            <div>
              <TopMiddle footerVisible={footerVisible}>
                <SectionItem>
                  <FooterNav href="#">Services</FooterNav>
                  <SubMenu>
                    <Link href="#">Dedicated Team Services</Link>
                    <Link>Cloud App Development</Link>
                    <Link>Web Application Development</Link>
                    <Link>Mobile Application Development</Link>
                    <Link>Software Architecture Design</Link>
                    {/* <Link>UI/UX Design Services</Link> */}
                    <Link>Quality Assurance Services</Link>
                    <Link>Application Support and Maintenance</Link>
                    <Link>Distriuted Systems Development</Link>
                  </SubMenu>
                </SectionItem>
                <SectionItem>
                  <FooterNav>Project</FooterNav>
                  <SubMenu></SubMenu>
                </SectionItem>
                <SectionItem>
                  <FooterNav>Career</FooterNav>
                  <SubMenu>
                    <Link>Welcome to Scrumanac</Link>
                    <Link>Open Vacancies</Link>
                    <Link>Internship Program</Link>
                  </SubMenu>
                </SectionItem>
                <SectionItem>
                  <FooterNav>Blog</FooterNav>
                  <SubMenu></SubMenu>
                </SectionItem>

                <SectionItem>
                  <FooterNav>About Scrumanac</FooterNav>
                  <SubMenu>
                    <Link>About Scrumanac</Link>
                    <Link>How we work</Link>
                    <Link>Our clients</Link>
                  </SubMenu>
                </SectionItem>
                <SectionItem style={{ marginLeft: "40px", width: "150px" }}>
                  {footerVisible ? (
                    <SocialSubMenu footerVisible={footerVisible}>
                      <a
                        href=" https://www.instagram.com/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon />
                      </a>

                      <a
                        href=" https://twitter.com/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterIcon />
                      </a>

                      <a
                        href="https://www.facebook.com/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FacebookIcon />
                      </a>
                      {/* <VimeoIcon /> */}
                      <a
                        href="https://www.linkedin.com/company/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <StyledLinkedin />
                      </a>

                      {/* <StyledWhatsapp /> */}
                      <a email href={"mailto:hello@scrumanac.com"}>
                        <StyledAt />
                      </a>
                    </SocialSubMenu>
                  ) : (
                    ""
                  )}
                </SectionItem>
              </TopMiddle>
              <RightContainer>
                <PrivacyContainer>
                  <span>All Rights Reserved. Scrumanac, LLC 2021</span>
                  <Demarcator />
                  <PrivacyLink href="https://scrumanac.com/">
                    Privacy policy
                  </PrivacyLink>
                </PrivacyContainer>
                <CopyrightSocial style={{ marginLeft: "24px" }}>
                  {!footerVisible ? (
                    <SocialSubMenu
                      style={{ marginLeft: "28px" }}
                      footerVisible={footerVisible}
                    >
                      <a
                        href=" https://www.instagram.com/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <InstagramIcon />
                      </a>

                      <a
                        href=" https://twitter.com/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <TwitterIcon />
                      </a>

                      <a
                        href="https://www.facebook.com/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <FacebookIcon />
                      </a>
                      {/* <VimeoIcon /> */}
                      <a
                        href="https://www.linkedin.com/company/scrumanac"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <StyledLinkedin />
                      </a>

                      {/* <StyledWhatsapp />
                      <StyledWhatsapp /> */}
                      <a email href={"mailto:hello@scrumanac.com"}>
                        <StyledAt />
                      </a>
                    </SocialSubMenu>
                  ) : (
                    ""
                  )}
                </CopyrightSocial>
              </RightContainer>
            </div>
          </ItemContainer>
        </MainFooterContainer>
      </FooterWrapper>
    </Container>
  );
}

const Container = styled.div`
  display: flex;
  flex-direction: column;
  font-family: 'Archivo';
  background: #191919;
  width: 100%;
  z-index: 100;
  justify-content: space-between;
  @media (max-width: 970px) {
  }
`;

const ToggleContainer = styled.div`
  display: flex;
  color: grey;
  max-width: 131px;
  cursor: pointer;
  margin-left: auto;
  font-size: 14px;
  padding-bottom: 50px;
  align-self: flex-end;
  @media (max-width: 970px) {
    display: flex;
    flex-direction: row;
    margin-left: auto;
    margin-right: auto;
  }
`;

const MainFooterContainer = styled.div`
  padding: 5px 20px;
  will-change: padding;
  -webkit-transition: padding 0.3s;
  transition: padding 0.3s;
  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
  }
`;

const ItemContainer = styled.div`
  display: flex;
  flex-direction: row;
  color: white;
  transition: display ease-in 2s;
  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
    width: 100%;
    align-items: center;
  }
`;

const RightContainer = styled.div`
  text-align: center;
  font-size: 0.875rem;
  color: #fff;
  display: flex;
  width: 100%;
  font-family: 'Archivo';
  font-weight: 300;
  background: #191919;
  will-change: padding, top;
  -webkit-transition: padding 0.3s, top 0.3s;
  transition: padding 0.3s, top 0.3s;
  padding-top: 0.5rem;
  justify-content: space-between;
  padding-bottom: 2rem;
  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
  }
`;

const PrivacyContainer = styled.div`
  width: 680px;
  display: flex;
  padding-top: 10px;
  font-weight: 550;
  justify-content: center;
  @media (max-width: 970px) {
    display: none;
  }
`;

const CopyrightSocial = styled.div`
  order: 1;
  display: flex;

  /* align-self: center; */
  @media (max-width: 970px) {
    width: 100%;
  }
`;

const SocialSubMenu = styled.ul`
  display: flex;
  flex-direction: row;
  width: 100%;
  align-items: center;
  margin-left: 70px;
  flex-wrap: ${(props) => (props.footerVisible ? "wrap" : "nowrap")};
  @media (max-width: 970px) {
    width: 100%;
    margin-left: auto !important;
    margin-top: 40px;
    margin-bottom: 50px;
    flex-wrap: nowrap;
    margin-right: auto !important;
  }
`;

const InstagramIcon = styled(FaInstagram)`
  display: flex;
  margin-top: 5px;
  flex-basis: 20%;
  margin-left: 4px;
  margin-right: 4px;
  padding: 5px;
  width: 30px;
  justify-content: center;
  height: 30px;
  align-items: center;
  background: #242424;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: gray;
    color: #fff;
  }
`;

const TwitterIcon = styled(FaTwitter)`
  display: flex;
  margin-top: 5px;
  flex-basis: 20%;
  margin-left: 4px;
  padding: 5px;
  margin-right: 4px;
  width: 30px;
  justify-content: center;
  height: 30px;
  align-items: center;
  background: #242424;
  color: #fff;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: gray;
    color: #fff;
  }
`;

const FacebookIcon = styled(FaFacebook)`
  display: flex;
  margin-top: 5px;
  flex-basis: 20%;
  width: 30px;
  margin-left: 4px;
  margin-right: 4px;
  justify-content: center;

  height: 30px;
  align-items: center;
  background: #242424;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: gray;
    color: #fff;
  }
`;
// const VimeoIcon = styled(FaVimeo)`
//   display: flex;
//   margin-top: 5px;
//   flex-basis: 20%;
//   width: 30px;
//   justify-content: center;
//   height: 30px;
//   margin-left: 4px;
//   margin-right: 4px;
//   align-items: center;
//   background: #242424;
//   color: #fff;
//   cursor: pointer;
//   padding: 5px;
//   transition: background 0.2s;
//   &:hover {
//     background: gray;
//     color: #fff;
//   }
// `;

const SectionItem = styled.div`
  display: flex;
  flex-direction: column;
  /* min-width: 110px; */
  margin-right: 10px;
  margin-left: 10px;
  padding-right: 0px;
  align-self: flex-start;
  @media (max-width: 970px) {
    margin-left: auto !important;
    margin-right: auto !important;
    width: 100% !important;
  }
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex-basis: 10%;
  align-self: flex-start;
  margin-right: 100px;
  @media (max-width: 970px) {
    align-items: center;
    width: 100%;
    margin-right: 0px;
  }
`;

const ScrumanacTitle = styled(Link)`
  display: flex;
  font-weight: 700;
  font-size: 25px;
  color: #fff;
  margin-right: 20px;
  opacity: 1;
  margin-top: ${(props) => (props.footerVisible ? "0px" : "8px")};
  @media (max-width: 970px) {
    align-self: center;
    margin-bottom: 50px;
    margin-right: 0px;
  }
  &:hover {
    color: #fff;
    opacity: 0.7;
  }
`;
const SubMenu = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  line-height: 1.4rem;
  font-size: 20px;
  & a {
    display: flex;
    flex-wrap: wrap;
    font-size: 13px;
    color: white;
    flex-basis: 100%;
    opacity: 0.4;
    &:hover {
      opacity: 1;
    }
  }

  @media (max-width: 970px) {
    align-items: center;
  }
`;

const FooterNav = styled(Link)`
  padding-top: 5px;
  font-weight: 1000;
  padding-bottom: 5px;
  width: 100%;
  display: flex;
  font-weight: 700;
  align-items: center;
  font-size: 14px;
  opacity: 1;
  text-transform: uppercase;
  &:hover {
    opacity: 0.5;
    color: #0066b2;
  }
  @media (max-width: 970px) {
    text-align: center;
    width: 100%;
    margin-left: auto;
    display: flex;
    flex-direction: column;
  }
`;

const StyledChevron = styled(FaChevronDown)`
  color: #0066b2;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
`;

const StyledChevronUp = styled(FaChevronUp)`
  color: #0066b2;
  padding: 5px;
  margin-right: 4px;
  margin-left: auto;
  transition: all 1000ms ease-in-out;
`;

// const StyledWhatsapp = styled(FaWhatsapp)`
//   display: flex;
//   margin-top: 5px;
//   flex-basis: 20%;
//   width: 30px;
//   justify-content: center;
//   margin-left: 4px;
//   margin-right: 4px;
//   height: 30px;
//   align-items: center;
//   background: #242424;
//   color: #fff;
//   padding: 5px;
//   cursor: pointer;
//   transition: background 0.2s;
//   &:hover {
//     background: gray;
//     color: #fff;
//   }
// `;

const StyledLinkedin = styled(FaLinkedin)`
  display: flex;
  margin-top: 5px;
  flex-basis: 20%;
  width: 30px;
  margin-left: 4px;
  margin-right: 4px;
  justify-content: center;
  height: 30px;
  align-items: center;
  background: #242424;
  color: #fff;
  padding: 6px;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: gray;
    color: #fff;
  }
`;

const StyledAt = styled(FaAt)`
  display: flex;
  margin-top: 5px;
  flex-basis: 20%;
  margin-left: 4px;
  margin-right: 4px;
  width: 25px;
  justify-content: center;
  height: 30px;
  align-items: center;
  background: #242424;
  color: #fff;
  padding: 5px;
  cursor: pointer;
  transition: background 0.2s;
  &:hover {
    background: gray;
    color: #fff;
  }
`;
const Demarcator = styled.p`
  margin: 20px;
  height: 100%;
  content: "";
  background: #fff;
  width: 1px;
  margin-top: 0px;
  opacity: 0.4;
`;

const FooterWrapper = styled.div`
  max-width: 100%;
  margin-left: auto;
  padding-top: 20px;
  margin-right: auto;
`;

const TopMiddle = styled.div`
  display: flex;
  justify-content: space-between;
  max-height: ${(props) => (props.footerVisible ? "100%" : 0)};
  overflow: ${(props) => (props.footerVisible ? "" : "hidden")};
  transition: all ease-in-out 0.5s;
  max-width: 1300px;
  @media (max-width: 970px) {
    display: flex;
    flex-direction: column;
  }
`;

const PrivacyLink = styled(Link)`
  opacity: 1;
  &:hover {
    opacity: 0.9;
    color: #0066b2;
  }
`;
