import React, { useState, useEffect } from "react";
import styled from "styled-components";
import CustomersForm from "./CustomersForm";
import CareerForm from "./CareerForm";
import OtherForms from "./OthersForm";
const ContactUsForm = () => {
  const [displayCustomers, setDisplayCustomers] = useState(true);
  const [displayCareer, setDisplayCareer] = useState(false);
  const [displayOthers, setDisplayOthers] = useState(false);
  const [changeForm, setChangeForm] = useState(false);
  const [checkPathname, setCheckPathname] = useState(true);

  useEffect(() => {
    window.location.pathname === "/"
      ? setCheckPathname(true)
      : setCheckPathname(false);
  }, []);

  const toggleForm = function (item) {
    setChangeForm(!changeForm);

    if (item === "customers") {
      setDisplayCustomers(true);
      setDisplayCareer(false);
      setDisplayOthers(false);
    } else if (item === "career") {
      setDisplayCustomers(false);
      setDisplayCareer(true);
      setDisplayOthers(false);
    } else if (item === "others") {
      setDisplayCustomers(false);
      setDisplayCareer(false);
      setDisplayOthers(true);
    }
  };

  return (
    <container>
      <FormToggle>
        <FormToggleItem
          path={checkPathname}
          display={displayCustomers}
          onClick={() => toggleForm("customers")}
        >
          Customers
        </FormToggleItem>
        <FormToggleItem
          path={checkPathname}
          display={displayCareer}
          onClick={() => toggleForm("career")}
        >
          Careers
        </FormToggleItem>
        <FormToggleItem
          path={checkPathname}
          display={displayOthers}
          onClick={() => toggleForm("others")}
        >
          Other
        </FormToggleItem>
      </FormToggle>
      <FormContainer changeForm={changeForm}>
        {displayCustomers && (
          <CustomersForm displayCustomers={displayCustomers} />
        )}
        {displayCareer && <CareerForm display={displayCareer} />}
        {displayOthers && <OtherForms display={displayOthers} />}
      </FormContainer>
    </container>
  );
};

const container = styled.div`
  display: flex;
  margin-left: auto;
  margin-right: auto;
  text-align: center;
  font-family: 'Archivo';
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 500px;
  margin-left: auto;
  margin-right: auto;

  @media (max-width: 570px) {
    width: 350px;
  }
  @media (max-width: 450px) {
    width: 250px;
  }
`;

const FormToggle = styled.div`
  display: flex;
  width: 30px;
  margin-left: auto;
  margin-right: auto;
  justify-content: center;
`;

const FormToggleItem = styled.p`
  color: #fff;
  border-radius: 6px;
  text-align: center;
  font-size: 20px;
  display: ${(props) => (props.path ? "block" : "none")};
  padding: 10px;
  width: 120px;
  margin: 2px;
  background: ${(props) => (props.display ? "#0066b2" : "")};
  /* transition: background ease-in .5s; */
  cursor: pointer;
`;

export default ContactUsForm;
