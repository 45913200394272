import React from "react";
import styled from "styled-components";

const SelectInputField = ({changeEvent,name}) => {

  
  return (
    <Select name={name} onChange={changeEvent} >
      <option>Web Or Mobile App</option>
      <option selected>Free Consulting</option>
      <option>Project Estimate</option>
      <option>Team Extension</option>
      <option>Dedicated team</option>
      <option>BOT Model</option>
      <option>Other</option>
    </Select>
  );
};



const Select = styled.select`
  border-radius: 10px;
  width: 100%;
  height: 55px;
  padding: 13px;
  font-size:17px;
font-weight: 600;
  margin-top: 10px;
  margin-bottom: 10px;
  background: #fff;
  color:#808080f8;
`;



export default SelectInputField;
